import { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow'
import { Box, HStack, Text } from '@chakra-ui/react';
import { FilterIcon, RefreshCcw, Share } from 'lucide-react';
import { useStore } from '../../store/store';

export const ConvoHeader = ({ isLoading, handleClearClick }) => {
    const [isAnimating, setIsAnimating] = useState(false);
    const widgetLinkColor = useStore(state => state.widgetSession.appearanceConfig?.linkColor)
    const { widgetSession, 
        isShowFilterPanel,
        setIsShowFilterPanel,
        searchResults,
        refinedSynthFilters,
        selectedFilters,
        widgetLog,
    } = useStore(useShallow(state => ({
        widgetSession: state.widgetSession,
        isShowFilterPanel: state.isShowFilterPanel,
        setIsShowFilterPanel: state.setIsShowFilterPanel,
        searchResults: state.searchResults,
        refinedSynthFilters: state.refinedSynthFilters,
        selectedFilters: state.selectedFilters,
        widgetLog: state.widgetLog
    })))

    useEffect(() => {
        if (widgetLinkColor) {
            document.documentElement.style.setProperty('--widget-link-color', widgetLinkColor);
        }
    }, [widgetLinkColor]);

    useEffect(() => {        
        setIsAnimating(true);
        const timer = setTimeout(() => setIsAnimating(false), 1000);
        return () => clearTimeout(timer);        
    }, [JSON.stringify(selectedFilters)]);

    const convos = useStore(state => state.convos)
    const setSendHistoryForm = useStore(state => state.setSendHistoryForm)

    const handleSendHistory = () => {
        setSendHistoryForm(true)
    }

    const handleFilterClick = () => {
        setIsShowFilterPanel(true)
        widgetLog && widgetLog('filters_click')
    }

    return <Box
        css={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '9px 0px',
            gap: '18px',
            position: 'sticky',
            top: 0,
            zIndex: 1,
            // margin: '0px 18px 9px 18px',
        }}
    >
        <HStack>
            <div className="h-12 leading-6 flex items-center text-white text-[16px] font-semibold">
                Conversation
            </div>
        </HStack>
        <HStack spacing={4}>            
            {Array.isArray(searchResults) && searchResults.length > 0 
            && Array.isArray(refinedSynthFilters) && refinedSynthFilters.length > 0 && // preventing filter showing on follow-up questions of a search result
                <Text
                    onClick={handleFilterClick}
                    style={{
                        cursor: isLoading ? 'none' : 'pointer',
                        fontWeight: 600,
                        fontSize: '13px',
                        lineHeight: '18px',
                        color: widgetLinkColor,
                        opacity: isLoading ? 0.2 : 1,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                    }}>
                    <FilterIcon 
                        size={16} 
                        className={isAnimating ? 'filter-icon-shake' : ''} 
                        style={{
                            color: Object.keys(selectedFilters || {}).length > 0 ? widgetLinkColor : 'inherit'
                        }}
                    /> 
                    filters
                </Text>}
            {Array.isArray(convos) && convos.length > 1 && (
                <Text
                    onClick={handleSendHistory}
                    _hover={{ color: '#CCB4EB' }}
                    _active={{ color: '#7850A0' }}
                    mr="8px"
                    style={{
                        cursor: isLoading ? 'none' : 'pointer',
                        fontWeight: 600,
                        fontSize: '13px',
                        lineHeight: '18px',
                        color: widgetLinkColor,
                        opacity: isLoading ? 0.2 : 1,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                    }}
                >
                    <Share size={16} /> share
                </Text>
            )}
            <Text
                id="cv-clear"
                onClick={isLoading ? null : handleClearClick}
                _hover={{ color: '#CCB4EB' }}
                _active={{ color: '#7850A0' }}
                style={{
                    cursor: isLoading ? 'none' : 'pointer',
                    fontWeight: 600,
                    fontSize: '13px',
                    lineHeight: '18px',
                    color: widgetLinkColor,
                    opacity: isLoading ? 0.2 : 1,
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                }}
            >
                <RefreshCcw size={16} /> clear
            </Text>
        </HStack>
    </Box>
}