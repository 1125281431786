import { useState } from 'react';
import { useStore } from '../../store/store';
import { useShallow } from 'zustand/react/shallow';
import { SynthFilters } from './SynthFilters';
import { PriceFilters } from './PriceFilters';
import { Button } from '@chakra-ui/react';
import { useSubmitHandler } from '../../hooks/useSubmitHandler';

export const FilterPanel = () => {    
    const { refinedSynthFilters,
        setIsShowFilterPanel,
        selectedFilters, 
        setSelectedFilters,
        resetSelectedFilters,
        isEntLoading,
        widgetButtonColor,
        synthFilters
    } = useStore(useShallow(state => ({
        refinedSynthFilters: state.refinedSynthFilters,
        setIsShowFilterPanel: state.setIsShowFilterPanel,
        selectedFilters: state.selectedFilters,
        setSelectedFilters: state.setSelectedFilters,
        resetSelectedFilters: state.resetSelectedFilters,
        isEntLoading: state.isEntLoading,
        widgetButtonColor: state.widgetSession.appearanceConfig?.buttonColor,
        synthFilters: state.synthFilters
    })));

    let defaultOpeningFilters = [...refinedSynthFilters.map(filter => filter[0]), 'price'];    
    const [openingFilters, setOpeningFilters] = useState(defaultOpeningFilters);
    const { handlePromptSubmit } = useSubmitHandler()

    const isFilterOpening = (key) => openingFilters.includes(key);
    const toggleFilter = (key) => {
        setOpeningFilters(prev => prev.includes(key) ? prev.filter(k => k !== key) : [...prev, key]);
    }

    const isFilterSelected = (key, value) => {
        return selectedFilters.some(filter => filter[0] === key && filter[1] === value);
    };

    const handleFilterClick = (key, value) => {        
        const newFilters = isFilterSelected(key, value)
            ? selectedFilters.filter(filter => filter[0] !== key || filter[1] !== value)
            : [...selectedFilters, [ key, value ]];
        setSelectedFilters(newFilters);
    };

    const handleSubmit = async () => {
        let reformattedFilters = {};
        selectedFilters.forEach(filter => {
            if (filter[0] === 'min_price' || filter[0] === 'max_price') {
                const priceValue = Number(filter[1])
                if (priceValue > 0) {
                    reformattedFilters[filter[0]] = priceValue;
                }
            } else {
                if (reformattedFilters[filter[0]]) {
                    reformattedFilters[filter[0]].push(filter[1]);
                } else {
                    reformattedFilters[filter[0]] = [filter[1]];
                }
            }
        });
            
        await handlePromptSubmit({ filterData: reformattedFilters, originalFilters: synthFilters });
        setIsShowFilterPanel(false);
    }

    return (
        <div className='px-[20px] text-[#e4e0e0] h-[calc(100vh-78px)] overflow-y-auto'>
            <div className="grid grid-cols-2 gap-4">
           
                <Button
						variant="outline"
						isDisabled={isEntLoading}
                        onClick={resetSelectedFilters}
						backgroundColor="transparent"
						borderRadius="32px"
						color="rgb(250,248,248)"
						fontSize="13px"
						lineHeight="20px"
						padding="8px 20px"
						mb="17.99px"
						_hover={{ filter: 'brightness(1.1)' }}
						_active={{ filter: 'brightness(0.9)' }}
						_disabled={{ filter: 'brightness(0.7)' }}
					>
						Reset
					</Button> 
                    
                    <Button
						type="submit"
						isDisabled={isEntLoading}
                        onClick={handleSubmit}
						backgroundColor={widgetButtonColor}
						borderRadius="32px"
						color="rgb(250,248,248)"
						fontSize="13px"
						lineHeight="20px"
						padding="8px 20px"                        
						mb="17.99px"
						_hover={{ filter: 'brightness(1.1)' }}
						_active={{ filter: 'brightness(0.9)' }}
						_disabled={{ filter: 'brightness(0.7)' }}
					>
						Submit
					</Button>
               
            </div>
            <PriceFilters
                isFilterOpening={isFilterOpening}
                toggleFilter={toggleFilter}
            />
            <SynthFilters
                filters={refinedSynthFilters}
                handleFilterClick={handleFilterClick}
                isFilterSelected={isFilterSelected}
                isFilterOpening={isFilterOpening}
                toggleFilter={toggleFilter}
            />
        </div>
    );
}