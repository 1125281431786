import { useState, useContext } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useStore } from '../../store/store.js';
import { BotTyping } from '../Animation/Typing/BotTyping.jsx';
import DocChipList from '../DocChip/DocChipList.jsx';
import { convoSettings } from '../../settings';
import { ThumbBox } from './Thumbs/ThumbBox.jsx';
import { utils, sentryUtils } from '../../lib/index.js';
import RelatedQuestions from '../RelatedQuestions/RelatedQuestions.jsx';
import { Context } from '../../store/context.js';
import { BotBubble } from './BotBubble.jsx';

// Streaming feature, once the stream is finished it's being changed to the backend response.
export const BotResponse = ({ isCurrent, convo }) => {
	const {
		widgetSession,
		avatar,
		updateConvo,
		fetchWeb,
		setContactSupportForm,
		bubbleWidth,
		widgetLog,
	} = useStore(useShallow(state => ({
		widgetSession: state.widgetSession,
		avatar: state.widgetSession.appearanceConfig.avatar,
		updateConvo: state.updateConvo,
		fetchWeb: state.fetchWeb,
		setContactSupportForm: state.setContactSupportForm,
		bubbleWidth: state.bubbleWidth,
		widgetLog: state.widgetLog,
	})));

	const { handleOpenDoc } = useContext(Context);
	const [thumbStatus, setThumbStatus] = useState(null);
	const msg = utils.transformBotMessage(convo.botResponse);
	const buttonColor = useStore(state => state.widgetSession.appearanceConfig?.linkColor);

	const handleWebSearchClick = async () => {
		if (convo.isWebClicked) return;
		try {
			await fetchWeb({ targetRequestId: convo.requestId });
			updateConvo({ requestId: convo.requestId, isWebClicked: true });
		} catch (err) {
			sentryUtils.captureError(err);
		}
	};

	const stopShowWeb = () => {
		updateConvo({ requestId: convo.requestId, isShowWebStopped: true });
	};

	const checkIsWebSearchSuggested = (botResponse) => botResponse.toLowerCase().includes(convoSettings.WEB_SEARCH_KEYWORD) && botResponse[botResponse.length - 1] === '?';

	const checkIsFindSimilarSuggested = (botResponse) => botResponse.toLowerCase().includes(convoSettings.FIND_SIMILAR_KEYWORD) && botResponse[botResponse.length - 1] === '?';

	return (
		<>
			{!convo.isShowWebStopped && (
				<>
					{/* Web Search */}
					{convo.isWebLoading && (
						<div className="pb-[9px]">
							<BotTyping avatar={avatar} bubbleWidth={bubbleWidth} />
							<div className="flex space-x-1 text-xs pt-1 pb-1 pl-[18px]">
								{convo.isWebClicked ? (
									checkIsWebSearchSuggested(convo.botResponse) ? (
										<>
											<div>searching the web ... it's a big place!</div>
											<div className="text-[#d96c6d] hover:cursor-pointer hover:opacity-80" onClick={stopShowWeb}>stop looking</div>
										</>
									) : checkIsFindSimilarSuggested(convo.botResponse) ? (
										<>
											<div>researching item ... hang tight!</div>
											<div className="text-[#d96c6d] hover:cursor-pointer hover:opacity-80" onClick={stopShowWeb}>stop looking</div>
										</>
									) : null
								) : null}
							</div>
						</div>
					)}
				</>
			)}
			{msg &&
				<div className="flex mb-[-9px]">
					<BotBubble
						convo={convo}
						widgetLog={widgetLog}
						textContent={msg}
						bubbleWidth={bubbleWidth}
						handleOpenDoc={handleOpenDoc}
						WebButton={isCurrent && checkIsWebSearchSuggested(convo.botResponse) && (
							!convo.isWebClicked ?
								() => (
									<div
										className="py-1 px-3 rounded-full border-2 text-xs cursor-pointer"
										onClick={handleWebSearchClick}
										style={{
											borderColor: buttonColor,
											transition: 'border-color 0.3s, background-color 0.3s',
										}}
										onMouseEnter={(e) => {
											e.currentTarget.style.borderColor = buttonColor;
											e.currentTarget.style.backgroundColor = buttonColor;
										}}
										onMouseLeave={(e) => {
											e.currentTarget.style.borderColor = buttonColor;
											e.currentTarget.style.backgroundColor = 'transparent';
										}}
									>
										{convoSettings.WEB_BUTTON_TEXT}
									</div>
								)
								:
								() => (
									<div className="py-1 px-3 rounded-full border-2 border-[#6b5983] text-xs text-gray-400">
										{convoSettings.WEB_BUTTON_TEXT}
									</div>
								)
						)}
						FindSimilarButton={isCurrent && checkIsFindSimilarSuggested(convo.botResponse) && (
							!convo.isWebClicked ?
								() => (
									<div
										className="py-1 px-3 rounded-full border-2 text-xs cursor-pointer"
										onClick={handleWebSearchClick}
										style={{
											borderColor: buttonColor,
											transition: 'border-color 0.3s, background-color 0.3s',
										}}
										onMouseEnter={(e) => {
											e.currentTarget.style.borderColor = buttonColor;
											e.currentTarget.style.backgroundColor = buttonColor;
										}}
										onMouseLeave={(e) => {
											e.currentTarget.style.borderColor = buttonColor;
											e.currentTarget.style.backgroundColor = 'transparent';
										}}
									>
										{convoSettings.FIND_SIMILAR_BUTTON_TEXT}
									</div>
								)
								:
								() => (
									<div className="py-1 px-3 rounded-full border-2 border-[#6b5983] text-xs text-gray-400">
										{convoSettings.FIND_SIMILAR_BUTTON_TEXT}
									</div>
								)
						)}
						SourceDocs={Array.isArray(convo.sourceDocs) && convo.sourceDocs.length > 0 && (
							(() => <DocChipList sourceDocs={convo.sourceDocs} />)
						)}
						SendUsMessage={widgetSession.appearanceConfig?.storePhone && msg.includes(widgetSession.appearanceConfig?.storePhone) && !convo.customerSupportForm && widgetSession.appearanceConfig?.supportEmail && (
							() => <div className="py-[3px] px-[9px] rounded-[32px] border-[1px] border-[rgba(170,133,217,0.50)] hover:border-[#8a6fae] hover:bg-[#8a6fae] text-[13px] font-normal cursor-pointer" onClick={() => { setContactSupportForm(true) }}>
								send us a message
							</div>
						)}
						RelatedQuestions={Array.isArray(convo.relatedQuestions) && convo.relatedQuestions.length > 0 && (
							(() => <RelatedQuestions questions={convo.relatedQuestions} />)
						)}
					/>
					{/* Only current message and non-welcome message have the thumb box*/}
					{isCurrent && !convo.isWelcomeMessage &&
						<div>
							<ThumbBox isPositive={true} thumbStatus={thumbStatus} setThumbStatus={setThumbStatus} convo={convo} />
							<ThumbBox isPositive={false} thumbStatus={thumbStatus} setThumbStatus={setThumbStatus} convo={convo} />
						</div>
					}
				</div>
			}
		</>
	);
}

