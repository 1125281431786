import { Box, VStack } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import MessageViewQuery from './MessageViewQuery.jsx';
import { Context } from '../../store/context.js';
import { useContext } from 'react';

export const BotBubble = ({
    convo,
    textContent,
    bubbleWidth,
    handleOpenDoc,
    WebButton,
    FindSimilarButton,
    SourceDocs,
    SendUsMessage,
    RelatedQuestions,
    widgetLog,
}) => {
    const { handleOpenVideoPanel } = useContext(Context)

    const handleWelcomeMessageClick = () => {
        if (convo.welcomeVideoUrl) {
            handleOpenVideoPanel(convo.welcomeVideoUrl);
            widgetLog('intro_video_play');
        }
    }

    return (
        <div
            name="response-bubble"
            className={`flex pb-3 h-full flex-row items-start gap-3`}
            style={{ width: bubbleWidth }}
        >
            <VStack display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start" gap="8px" flex="1 0 0">
                <Box
                    borderRadius="0px 12px 12px 12px"
                    backgroundColor="rgba(255,255,255,0.07)"
                    display="flex"
                    padding="18px"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    gap="9px"
                    flex="1 0 0"
                    width="100%"
                    cursor={convo.welcomeVideoUrl ? 'pointer' : 'default'}
                    _hover={{
                        backgroundColor: convo.welcomeVideoUrl ? 'rgba(255,255,255,0.09)' : 'rgba(255,255,255,0.07)',
                    }}
                    onClick={convo.isWelcomeMessage ? handleWelcomeMessageClick : null}
                >
                    <div className="text-markdown bot-response-markdown">
                        {typeof textContent === 'string' ? (
                            <ReactMarkdown
                                remarkPlugins={[remarkGfm]}
                                components={{
                                    ol: ({ node, ...props }) => <ol style={{ paddingLeft: '18px' }} {...props} ordered="true" />,
                                    a: ({ node, ...props }) => (
                                        <span
                                            onClick={(event) => {
                                                event.preventDefault();
                                                const url = props.href;
                                                if (url.endsWith('.pdf')) {
                                                    handleOpenDoc(url, 0);
                                                } else {
                                                    window.parent.location.href = url;
                                                }
                                            }}
                                            style={{ color: 'var(--widget-link-color, #B794F4)', cursor: 'pointer' }}
                                        >
                                            {props.children}
                                        </span>
                                    ),
                                }}
                            >
                                {textContent}
                            </ReactMarkdown>
                        ) : (
                            textContent
                        )}
                    </div>
                    {WebButton && <WebButton />}
                    {FindSimilarButton && <FindSimilarButton />}
                    {SendUsMessage && <SendUsMessage />}
                    <MessageViewQuery convo={convo} />
                </Box>
                {SourceDocs && <SourceDocs />}
                {RelatedQuestions && <RelatedQuestions />}
            </VStack>
        </div>
    );
}