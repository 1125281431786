import axios from 'axios'
import { create } from 'zustand'
import { useApiConfig } from '../hooks/useApiConfig.js'
import { fetchWebSearch } from './fetchWebSearch.js'
import { sentryUtils } from '../lib/index.js'
import { filterSettings, widgetSettings } from '../settings'

export const useStore = create((set, get) => ({
    widgetSession: {
        appearanceConfig: {
            avatar: '/icon/logo-convo-light.png',
            buttonColor: '#44337A',
            linkColor: '#B794F4'
        }
    },
    setWidgetSession: (session) => {
        const defaultAppearanceConfig = get().widgetSession.appearanceConfig
        let { appearanceConfig = {} } = session; // Destructure to avoid directly modifying the input session object
        appearanceConfig = {
            ...appearanceConfig,
            avatar: appearanceConfig.avatar || defaultAppearanceConfig.avatar,
            buttonColor: appearanceConfig.buttonColor || defaultAppearanceConfig.buttonColor,
            linkColor: appearanceConfig.linkColor || defaultAppearanceConfig.linkColor,
        };
        const nextWidgetSession = { ...session, appearanceConfig }
        set({ widgetSession: nextWidgetSession, })
    },

    // Ent Response
    isEntLoading: false,
    setEntLoading: (status) => set(() => ({ isEntLoading: !!status })),
    searchResults: [],
    setSearchResults: (searchResults) => {
        if (!Array.isArray(searchResults)) return;

        const currentStore = get()
        if (currentStore.widgetSession.isInstantSearchEnabled) {
            window.parent.postMessage({
                action: 'cv-instant-search-results',
                searchResults: searchResults.filter(s => !s.hidden)
            }, '*')
        }
            set(() => ({searchResults}))
    },
    clearSearchResults: () => set({ searchResults: [] }),
    synthFilters: [],
    refinedSynthFilters: [],
    setSynthFilters: (synthFilters) => {
        const refinedSynthFilters = filterSettings.refineSynthFilters(synthFilters)
        set({ synthFilters, refinedSynthFilters })
    },
    clearFilters: () => set({ synthFilters: [] }),
    isShowFilterPanel: false,
    setIsShowFilterPanel: (isShowFilterPanel) => {
        set({ isShowFilterPanel })
    },

    // Previous Request Id
    prevSrpRequestId: null,
    setprevSrpRequestId: (prevSrpRequestId) => set({ prevSrpRequestId }),

    // Chat
    prompt: '',
    setPrompt: (prompt) => set({ prompt }),
    isPrompting: false,
    setIsPrompting: (isPrompting) => set({ isPrompting }),
    lastSearchPrompt: '',
    setLastSearchPrompt: (lastSearchPrompt) => set({ lastSearchPrompt }),

    // Convo format: [{ userPrompt, botResponse, requestId, searchResults, sourceDocs, isWebClicked, isWebLoading }]
    convos: null,
    replaceConvos: (convos) => set({ convos }),
    appendConvos: (convo) => set(state => {
        if (Array.isArray(state.convos) && state.convos.length > 0) {
            return ({ convos: [...state.convos, convo] })
        } else {
            return ({ convos: [convo] })
        }
    }),
    prependConvos: (convo) => set(state => ({ convos: [convo, ...state.convos] })),
    updateConvo: ({ requestId,
        text,
        sourceDocs,
        searchResults,
        synthFilters,
        isWebClicked,
        isWebLoading,
        isShowWebStopped,
        customerSupportForm,
        relatedQuestions,
        isHidden
    }) => set((state) => {
        const sourceDocObject = Array.isArray(sourceDocs) && sourceDocs.length > 0 ? { sourceDocs } : {}
        const searchResultsObject = Array.isArray(searchResults) ? { searchResults } : {}
        const synthFiltersObject = Array.isArray(synthFilters) ? { synthFilters } : {}
        const relatedQuestionsObject = Array.isArray(relatedQuestions) ? { relatedQuestions } : {}

        const newConvos = [...state.convos];
        if (newConvos.length > 0) {
            let convoIndex = newConvos.findIndex((c) => c.requestId === requestId);
            if (convoIndex === -1) { // if there is no matching requestId             
                convoIndex = 0 // attaching to the first entry              
            }
            // Update or add new chat based on `convoIndex`
            const updatedConvo = newConvos[convoIndex];

            newConvos[convoIndex] = {
                ...updatedConvo,
                ...(text ? { botResponse: (updatedConvo.botResponse || '') + text } : {}),
                // Note: verifying boolean values so it doesn't set true to false when there is no value passed in
                // verifying with typeof instead of shortcut so that it doesn't skip the false value passed in
                ...(typeof isWebClicked === 'boolean' ? { isWebClicked } : {}),
                ...(typeof isWebLoading === 'boolean' ? { isWebLoading } : {}),
                ...(typeof isShowWebStopped === 'boolean' ? { isShowWebStopped } : {}),
                ...(typeof isHidden === 'boolean' ? { isHidden } : {}),
                ...sourceDocObject,
                ...searchResultsObject,
                ...synthFiltersObject,
                ...relatedQuestionsObject,
                requestId,
                timestamp: updatedConvo.timestamp || new Date().toISOString(),
                sessionId: state.widgetSession.id,
                pageUrl: state.widgetSession.url,
                productName: state.widgetSession.productName,
                productSku: state.widgetSession.productSku,
                productSku: state.widgetSession.productSku,
                customerSupportForm

            };
        } else {
            // Handling an edge case case when there is no initial convo
            newConvos.push({
                ...(text ? { botResponse: text } : {}),
                ...(typeof isWebClicked === 'boolean' ? { isWebClicked } : {}),
                ...(typeof isWebLoading === 'boolean' ? { isWebLoading } : {}),
                ...(typeof isShowWebStopped === 'boolean' ? { isShowWebStopped } : {}),
                ...(typeof isHidden === 'boolean' ? { isHidden } : {}),
                ...sourceDocObject,
                ...searchResultsObject,
                ...synthFiltersObject,
                ...relatedQuestionsObject,
                requestId,
                timestamp: new Date().toISOString(),
                sessionId: state.widgetSession.id,
                pageUrl: state.widgetSession.url,
                productName: state.widgetSession.productName,
                productSku: state.widgetSession.productSku,
                productSku: state.widgetSession.productSku,
                customerSupportForm
            });
        }

        return { convos: newConvos }; // Return the updated state
    }),

    // Web search
    fetchWeb: async ({ targetRequestId }) => {
        const currentStore = get()
        await fetchWebSearch({
            targetRequestId,
            convos: currentStore.convos,
            prependConvos: currentStore.prependConvos,
            setEntLoading: currentStore.setEntLoading,
            widgetSession: currentStore.widgetSession,
            widgetLog: currentStore.widgetLog,
            updateConvo: currentStore.updateConvo,
            setSearchResults: currentStore.setSearchResults,
            setEntLoading: currentStore.setEntLoading,
            setprevSrpRequestId: currentStore.setprevSrpRequestId,
            setSynthFilters: currentStore.setSynthFilters,
            resetSelectedFilters: currentStore.resetSelectedFilters
        })
    },

    agentStatus: { requestId: null, status: '' },
    setAgentStatus: (agentStatus) => set({ agentStatus }),

    isTryingToLoadHistory: false,
    setIsTryingToLoadHistory: (isTryingToLoadHistory) => set({ isTryingToLoadHistory }),
    loadHistory: async (sessionId) => {
        const { apiBaseUrl } = useApiConfig()
        const endpoint = `${apiBaseUrl}/enterprise_session/${sessionId}`
        const currentStore = get()
        try {
            const { data } = await axios.get(endpoint)
            if (Array.isArray(data) && data.length > 0) {
                const convos = data.map(d => {
                    return {
                        userPrompt: d.user_message,
                        botResponse: d.bot_response,
                        requestId: d.request_id,
                        searchResults: d.search_results,
                        synthFilters: Object.entries(d.filters),
                        sourceDocs: d.source_docs,
                        productName: d.product_name,
                        productSku: d.product_sku,
                        pageUrl: d.page_url,
                    }
                })
                const currentWidgetSession = currentStore.widgetSession;
                if (Object.keys(currentWidgetSession).length === 0 || !currentWidgetSession.url) {
                    throw new Error('Missing widget session or url')
                }
                let parentUrl = new URL(currentWidgetSession.url);
                // If the URL contains sessionId (search page), 
                // update the search results and send to parent window
                if (parentUrl.searchParams.has('sessionId')) {
                    const latestConvo = convos[0];
                    if (latestConvo?.searchResults?.length) {
                        currentStore.setSearchResults(latestConvo.searchResults);
                        currentStore.setprevSrpRequestId(latestConvo.requestId);                        
                        const filterHistory = JSON.parse(sessionStorage.getItem('filterHistory'))
                        if (filterHistory[latestConvo.requestId]) {
                            currentStore.setSynthFilters(filterHistory[latestConvo.requestId].filters)
                            currentStore.setSelectedFilters(filterHistory[latestConvo.requestId].selected)
                        }
                    }
                }

                set({ convos })
                const latestSrpConvo = data.find(d => Array.isArray(d.search_results) && d.search_results.length > 0)
                if (latestSrpConvo) {
                    set({ prevSrpRequestId: latestSrpConvo.request_id })
                }
                if (Array.isArray(data)
                    && data[0]
                    && Array.isArray(Object.keys(data[0].filters))
                    && Object.keys(data[0].filters).length > 0) {

                    set({ synthFilters: Object.entries(data[0].filters) })
                    set({ lastSearchPrompt: data[0].user_message })

                }
            }

            return data
        } catch (err) {
            throw err
        }
    },
    widgetLog: async (eventName) => {
        const { apiBaseUrl } = useApiConfig()
        const sessionId = get().widgetSession.id
        const pageUrl = get().widgetSession.url
        const endpoint = `${apiBaseUrl}/store-event-log/`
        try {
            if (!sessionId || !pageUrl) {
                throw new Error(`Missing log parameters: ${sessionId} - ${pageUrl}`)
            }

            await fetch(endpoint, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                body: JSON.stringify({ event_name: eventName, session_id: sessionId, page_url: pageUrl }),
            })
        } catch (err) {
            sentryUtils.captureApiError(err, endpoint)
        }
    },

    //customer support form
    contactSupportForm: false,
    setContactSupportForm: (contactSupportForm) => set({ contactSupportForm }),
    email: '',
    setEmail: (email) => set({ email }),
    formName: '',
    setFormName: (formName) => set({ formName }),
    textDescription: '',
    setTextDescription: (textDescription) => set({ textDescription }),
    uploadedFiles: [],
    setUploadedFiles: (uploadedFiles) => set({ uploadedFiles }),
    formChatHistory: [],
    handleCustomerSupportFormPayload: () => {
        let history = [];
        const { widgetSession, convos } = get();
        convos.forEach((convo) => {
            const newHistory = {
                timestamp: convo.timestamp,
                session_id: convo.sessionId,
                request_id: convo.requestId,
                page_url: convo.pageUrl,
                user_message: convo.userPrompt,
                bot_response: convo.botResponse,
                product_name: convo.productName,
                product_sku: convo.productSku,
            };
            history.push(newHistory);
        })

        set({ formChatHistory: history });
    },

    formSubmitted: false,
    setFormSubmitted: (formSubmitted) => set({ formSubmitted }),
    handleCustomerSupportForm: async (payload) => {
        const { apiBaseUrl } = useApiConfig()
        const endpoint = `${apiBaseUrl}/customer-support-form/`
        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                body: JSON.stringify(payload),
            })
            const data = await response.json()
            if (data.status === "success") {
                set({ formSubmitted: true })
            }
        } catch (err) {
            sentryUtils.captureApiError(err, endpoint)
        }
    },
    fetchRequestStatus: async (requestId) => {
        const { apiBaseUrl } = useApiConfig()
        const endpoint = `${apiBaseUrl}/request_id_status/${requestId}`
        try {
            const response = await fetch(endpoint, {
                method: 'GET',
            })
            const data = await response.json()
            return data.status
        } catch (err) {
            sentryUtils.captureApiError(err, endpoint)
        }

    },

    //send history form
    sendHistoryForm: false,
    setSendHistoryForm: (sendHistoryForm) => set({ sendHistoryForm }),
    handleSendHistoryFormPayload: () => {
        let history = [];
        const { widgetSession, convos } = get();
        convos.forEach((convo) => {
            const newHistory = {
                timestamp: convo.timestamp,
                session_id: convo.sessionId,
                request_id: convo.requestId,
                page_url: convo.pageUrl,
                user_message: convo.userPrompt,
                bot_response: convo.botResponse,
                product_name: convo.productName,
                product_sku: convo.productSku,
            };
            history.push(newHistory);
        })

        set({ formChatHistory: history });
    },
    handleSendHistoryForm: async (payload) => {
        const { apiBaseUrl } = useApiConfig()
        const endpoint = `${apiBaseUrl}/send-history-form/`
        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                body: JSON.stringify(payload),
            })
            const data = await response.json()
            if (data.status === "success") {
                set({ formSubmitted: true })
            }
        } catch (err) {
            sentryUtils.captureApiError(err, endpoint)
        }
    },

    requestSession: () => {
        window.parent.postMessage({ action: 'cv-request-session' }, '*')
    },

    // UI
    bubbleWidth: widgetSettings.CHAT_PANEL_MIN_WIDTH - widgetSettings.CHAT_PANEL_BUBBLE_DIFF,
    setBubbleWidth: (bubbleWidth) => set({ bubbleWidth }),

    selectedFilters: [],
    setSelectedFilters: (newFilters) => set({ selectedFilters: newFilters }),
    resetSelectedFilters: () => set({ selectedFilters: [] }),
    
    /** {
        requestId: {
            filters: [[key,[values]]]
            selectedFilters: [[key,value]]
        }
     */
    filterHistory: {},    
    setFilterHistory: (filterHistory) => set({ filterHistory }),
    addIfNewEntryFilterHistory: ({requestId, filters, selected = []}) => {
        const currentStore = get()
        currentStore.setSynthFilters(filters)
        const refinedSynthFilters = filterSettings.refineSynthFilters(filters)        
        const newFilterHistory = {...currentStore.filterHistory, [requestId]: {
            filters: refinedSynthFilters,
            selected
        }}
        set({filterHistory: newFilterHistory})    
        sessionStorage.setItem('filterHistory', JSON.stringify(newFilterHistory))
    }
}))
