export const GENERATED_SEARCH_QUERY = 'generated search query'
export const IGNORED_FILTERS = [
    GENERATED_SEARCH_QUERY,
    'sku',
    'product reference guide',
    'spec sheet',    
    'datasheet',
    'catalog'
]
export const IGNORED_FILTERS_PREFIXES = ['note']

export const refineSynthFilters = (synthFilters) => {
    const refinedSynthFilters = synthFilters.filter(([key, values]) =>
        IGNORED_FILTERS.includes(key.toLowerCase()) === false
        && IGNORED_FILTERS_PREFIXES.some(prefix => key.toLowerCase().startsWith(prefix)) === false
        // && Array.isArray(values) && values.length > 1
    ).sort((a, b) => a[0].localeCompare(b[0]))

    return refinedSynthFilters
}