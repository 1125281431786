import { Box } from '@chakra-ui/react'
import React, { useEffect, useRef } from 'react';
import { useShallow } from 'zustand/react/shallow'
import { useEntResults } from '../hooks/useEntResults.js'
import { useStore } from '../store/store.js'
import { sentryUtils, utils } from '../lib/index.js';
import { useSubmitHandler } from '../hooks/useSubmitHandler.js'

const WidgetInput = ({
	inputRef,
	submitRef,
	isShowSRPanel,
	isShowDocPanel,
}) => {
	const {
		widgetSession,
		convos,
		prependConvos,
		prevSrpRequestId,
		widgetLog,
		prompt,
		setPrompt,
		searchResults,
		setSynthFilters,
		isEntLoading,
		setEntLoading,
		isPrompting,
		setIsPrompting,
		requestSession,
		setIsShowFilterPanel,
		isTryingToLoadHistory
	} = useStore(
		useShallow(state => ({
			widgetSession: state.widgetSession,
			convos: state.convos,
			prependConvos: state.prependConvos,
			prevSrpRequestId: state.prevSrpRequestId,
			widgetLog: state.widgetLog,
			prompt: state.prompt,
			setPrompt: state.setPrompt,
			searchResults: state.searchResults,
			setSynthFilters: state.setSynthFilters,
			isEntLoading: state.isEntLoading,
			setEntLoading: state.setEntLoading,
			isPrompting: state.isPrompting,
			setIsPrompting: state.setIsPrompting,
			requestSession: state.requestSession,
			setIsShowFilterPanel: state.setIsShowFilterPanel,
			isTryingToLoadHistory: state.isTryingToLoadHistory,
		}))
	);
	const isSubmitDisabled = isEntLoading || isTryingToLoadHistory
	const isSearchResultContext =
		widgetSession.isInstantSearchEnabled ? (Array.isArray(searchResults) && searchResults.length > 0)
			: isShowSRPanel

	const isValidPdp = !!widgetSession.productName

	const convosRef = useRef(convos);
	const isSmallDeviceRef = useRef(widgetSession.isSmallDevice);
	const { handlePromptSubmit } = useSubmitHandler()

	const { fetchEntResults } = useEntResults()

	useEffect(() => {
		isSmallDeviceRef.current = widgetSession.isSmallDevice;
	}, [widgetSession.isSmallDevice]);

	useEffect(() => {
		// Notify the merchant store that the page is ready by requesting the session
		requestSession()
	}, [])

	useEffect(() => {
		convosRef.current = convos;
	}, [JSON.stringify(convos)]);

	useEffect(() => {
		// const checkHistoryToSubmitPrompt = (event) => {
		// 	if (Array.isArray(convosRef.current) && convosRef.current.length > 0) {
		// 		// Necessary 3-second delay to ensure the history is fully rendered
		// 		setTimeout(() => handleSearchQuerySubmit(event.data), 3000);
		// 	} else {
		// 		setTimeout(() => checkHistoryToSubmitPrompt(event), 1000);
		// 	}
		// };

		window.addEventListener('message', (event) => {
			if (event.data.action === 'cv-search-query-init') {
				handleSearchQuerySubmit(event.data)
			}
		});
	}, []);


	const getWebsiteContext = () => {
		return isSmallDeviceRef.current ? 6 :
			isSearchResultContext ? 3 : isShowDocPanel ? 2 : isValidPdp ? 1 : 0
	}
	
	const handleSearchQuerySubmit = async ({ searchQuery, sessionId, productUrl }) => {
		if (!searchQuery) return
		setPrompt('')
		setSynthFilters([])
		setIsPrompting(false)
		setIsShowFilterPanel(false)
		setEntLoading(true)
		widgetLog && widgetLog('submit')

		try {
			const chronicle = utils.convertConvosToChronicle(convos, searchQuery)
			prependConvos({
				userPrompt: searchQuery
			})
			requestSession() // Update the current product URL in case the user has navigated to another page

			const data = JSON.stringify({
				user_id: sessionId,
				product_url: productUrl,
				product_name: '',
				user_input: searchQuery,
				message_history: chronicle,
				client_id: '',
				client_name: 'conversant',
				prev_srp_request_id: prevSrpRequestId,
				website_context: {
					website_context: getWebsiteContext()
				}
			})
			await fetchEntResults(data)
		} catch (err) {
			setEntLoading(false)
			sentryUtils.captureError(err)
		}
	}

	const handleSubmitWithWebsiteContext = async (e) => {
		e.preventDefault()
		await handlePromptSubmit({ websiteContext: getWebsiteContext() });
	}
	return (
		<Box background={'rgba(255,255,255,0.07)'} padding="10px" name="widgetInput">
			<form onSubmit={handleSubmitWithWebsiteContext} disabled={isSubmitDisabled} role="form">
				<label>
					<input
						id="cv-input"
						ref={inputRef}
						name="inputSend"
						autoComplete="off"
						value={prompt}
						onChange={(e) => {
							setPrompt(e.target.value)
							if (!e.target.value) {
								setIsPrompting(false)
								e.target.style.border = '1px solid red'
							} else {
								if (!isPrompting) {
									widgetLog && widgetLog('typing')
								}
								setIsPrompting(true)
								e.target.style.border = 'none'
							}
						}}
						type="text"
						placeholder={'Search & ask questions with AI…'}
						style={{ padding: '11px', height: '44px', fontSize: "13px" }}
					/>
					<button
						id="cv-submit"
						ref={submitRef}
						disabled={isSubmitDisabled}
						style={{ height: '44px', width: '44px', backgroundColor: isEntLoading ? '#7c7c7c' : widgetSession.appearanceConfig.buttonColor }}
					>
						<svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
							<path fillRule="evenodd" clipRule="evenodd" d="M13.7767 22.1528C13.774 21.6619 14.1698 21.2618 14.6607 21.2592L26.956 21.1923C27.4469 21.1897 27.847 21.5854 27.8497 22.0763C27.8523 22.5672 27.4566 22.9673 26.9657 22.97L14.6703 23.0368C14.1794 23.0395 13.7794 22.6437 13.7767 22.1528Z" fill="white" />
							<path fillRule="evenodd" clipRule="evenodd" d="M21.9737 15.4383C22.3278 15.0883 22.9058 15.0846 23.2646 15.4301L30 21.9147L23.273 28.5618C22.9188 28.9118 22.3408 28.9154 21.9821 28.5699C21.6233 28.2244 21.6195 27.6606 21.9737 27.3107L27.418 21.9311L21.9821 16.6975C21.6233 16.3521 21.6195 15.7883 21.9737 15.4383Z" fill="white" />
						</svg>
					</button>
				</label>
			</form>
		</Box>
	)
}

export { WidgetInput }
