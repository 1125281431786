import { FilterCategory } from './FilterCategory';
import { useShallow } from 'zustand/react/shallow';
import { useStore } from '../../store/store';

export const PriceFilters = ({ isFilterOpening, toggleFilter }) => {
    const { 
        setSelectedFilters,
        selectedFilters
    } = useStore(useShallow(state => ({
        setSelectedFilters: state.setSelectedFilters,
        selectedFilters: state.selectedFilters
    })));
    
    const handlePriceMinChange = (e) => {
        const newMinPrice = e.target.value || ''
        if (selectedFilters.some(filter => filter[0] === 'min_price')) {
            const newSelectedFilters = selectedFilters.map(filter => filter[0] === 'min_price' ? [ 'min_price', newMinPrice ] : filter)
            setSelectedFilters(newSelectedFilters);
        } else {
            setSelectedFilters([ ...selectedFilters, ['min_price', newMinPrice] ]);
        }
    };

    const handlePriceMaxChange = (e) => {const newMaxPrice = e.target.value || ''
        if (selectedFilters.some(filter => filter[0] === 'max_price')) {                    
            const newSelectedFilters = selectedFilters.map(filter => filter[0] === 'max_price' ? [ 'max_price', newMaxPrice ] : filter)
            setSelectedFilters(newSelectedFilters);
        } else {
            setSelectedFilters([ ...selectedFilters, ['max_price', newMaxPrice] ]);
        }
    };

    return (
        <div>
            <FilterCategory category="price" isFilterOpening={isFilterOpening} toggleFilter={toggleFilter} />

            {isFilterOpening('price') && (
                <div>
                       <div className="grid grid-cols-2 gap-4">
                            <input
                                type="number"
                                name="min"
                                placeholder="Min"
                                value={selectedFilters.find(filter => filter[0] === 'min_price')?.[1] || ''}
                                onChange={handlePriceMinChange}
                                className="border rounded px-2 py-1 text-sm"
                            />
                            {/* <span>to</span> */}
                            <input
                                type="number"
                                name="max"
                                placeholder="Max"
                                value={selectedFilters.find(filter => filter[0] === 'max_price')?.[1] || ''}
                                onChange={handlePriceMaxChange}
                                className="border rounded px-2 py-1 text-sm"
                            />
                        </div>
                    
                </div>
            )}
        </div>
    );
};