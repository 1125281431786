import { useRef, useEffect, useContext } from 'react';
import { useShallow } from 'zustand/react/shallow'
import { useStore } from '../store/store.js'
import { useApiConfig } from './useApiConfig.js'
import { sentryUtils } from '../lib/index.js'
import { widgetSettings } from '../settings'
import { Context } from '../store/context.js'

export function useEntResults() {
	const {
		convos,
		setSearchResults,
		setEntLoading,
		setprevSrpRequestId,
		setSynthFilters,
		widgetLog,
		updateConvo,
		widgetSession,
		setLastSearchPrompt,
		prompt,
		resetSelectedFilters,
		setAgentStatus,
		addIfNewEntryFilterHistory,
		selectedFilters,
		filterHistory
	} = useStore(useShallow(
		state => ({
			convos: state.convos,
			setSearchResults: state.setSearchResults,
			setEntLoading: state.setEntLoading,
			setprevSrpRequestId: state.setprevSrpRequestId,
			setSynthFilters: state.setSynthFilters,
			widgetLog: state.widgetLog,
			updateConvo: state.updateConvo,
			widgetSession: state.widgetSession,
			prompt: state.prompt,
			setLastSearchPrompt: state.setLastSearchPrompt,
			resetSelectedFilters: state.resetSelectedFilters,
			setAgentStatus: state.setAgentStatus,
			addIfNewEntryFilterHistory: state.addIfNewEntryFilterHistory,
			filterHistory: state.filterHistory,
			selectedFilters: state.selectedFilters
		})
	))
	const { setIsShowSRPanel, chatPanelCurrentWidth } = useContext(Context)
	const convosRef = useRef(convos);

	const { apiBaseUrl } = useApiConfig()

	useEffect(() => {
		convosRef.current = convos;
	}, [convos]);

	const fetchEntResults = async (payload, originalFilters) => {
		const endpoint = `${apiBaseUrl}/enterprise_qna_results`
		try {
			const response = await fetch(`${apiBaseUrl}/enterprise_qna_results`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: payload,
			})
			const data = await response.json()
			// For subsequent search
			if (originalFilters) {
				// const filterObj = JSON.parse(payload).filters
				// const selectedArray = Object.entries(filterObj).reduce((acc, [key, values]) => {
				// 	values.forEach(value => acc.push([key, value]))
				// 	return acc
				// }, [])
				addIfNewEntryFilterHistory({
					requestId: data.request_id, 
					filters: originalFilters, 
					selected: selectedFilters
				})
			}
			listenForUpdates(data.request_id, setIsShowSRPanel, chatPanelCurrentWidth, !!originalFilters)
		} catch (err) {
			sentryUtils.captureApiError(err, endpoint)
			setEntLoading(false)
			return false
		}
		return true
	}

	const updateMessage = ({ requestId, text, sourceDocs }) => {
		updateConvo({ requestId, text, sourceDocs })
		if (sourceDocs) {
			widgetLog && widgetLog('attribution_impression')
		}
	}

	// Subscribing to to stream
	function listenForUpdates(requestId, setIsShowSRPanel, chatPanelCurrentWidth, isSubsequentSearch) {
		const endpoint = `${apiBaseUrl}/enterprise_qna_response/${requestId}`
		const eventSource = new EventSource(endpoint)
		eventSource.onmessage = function (event) {
			if (event.data.includes('[STREAM DONE]')) {
				setEntLoading(false)
				setAgentStatus({ requestId, status: '' })
				eventSource.close()

			}
		}
		eventSource.addEventListener('answer', (event) => {
			setEntLoading(false)
			updateMessage({ requestId, text: event.data })
		})
		eventSource.addEventListener('source_docs', (event) => {
			const rawDocs = JSON.parse(event.data)
			// Validate docs fields
			const sourceDocs = rawDocs.filter(({ file_url, page_number }) => {
				// Check if file_url is a valid URL using a regular expression
				const urlPattern = /^(http|https):\/\/([a-zA-Z0-9.-]+(\.[a-zA-Z]{2,})?(:\d+)?(\/.*)?)$/
				const isFileUrlValid = file_url && urlPattern.test(file_url)

				// Check if page_number is a string representing an integer
				const isPageNumberValid = page_number && !isNaN(page_number) && Number.isInteger(parseFloat(page_number))
				// Return true if both conditions are met
				return isFileUrlValid && isPageNumberValid
			})
			updateMessage({ requestId, sourceDocs })
		})
		eventSource.addEventListener('search_results', (event) => {
			try {
				const searchResults = JSON.parse(event.data)
				updateConvo({ requestId, searchResults })
				if (Array.isArray(searchResults)) { // Allow empty SR to clear up the previous one					
					setSearchResults(searchResults)					
					setLastSearchPrompt(prompt)
					widgetLog('search_results')
					if (widgetSession.isSmallDevice) {
						widgetLog('mobile_search')
					}
					setprevSrpRequestId(requestId)
					if (searchResults.length === 1) {
						window.parent.postMessage({ action: 'cv-jackpot', product: searchResults[0] }, '*')
					} else {
						const ws = useStore.getState().widgetSession // get the most updated version of widgetSession
						if (!ws.isInstantSearchEnabled && searchResults.length > 0) {
							setIsShowSRPanel(true)
							window.parent.postMessage({
								action: 'cv-iframe-resize',
								width: chatPanelCurrentWidth + widgetSettings.SR_PANEL_DEFAULT_WIDTH,
								rightPanelWidth: widgetSettings.SR_PANEL_DEFAULT_WIDTH,
							}, '*',)
						}
					}
				}
			} catch (err) {
				sentryUtils.captureApiError(err, endpoint)
			}
		})
		eventSource.addEventListener('filters', (event) => {
			try {
				const data = JSON.parse(event.data)				
				// Update synth filters and add to filter history on the original search
				if (!isSubsequentSearch) {
					resetSelectedFilters()
					setSynthFilters(Object.entries(data))
					addIfNewEntryFilterHistory({
						requestId, 
						filters: Object.entries(data), 						
					})
				}
				updateConvo({ requestId, synthFilters: Object.entries(data) })
			} catch (err) {
				sentryUtils.captureApiError(err, endpoint)
			}
		})
		eventSource.addEventListener('related_questions', (event) => {
			try {
				const eventData = event.data
				let rq = []
				try {
					const { questions } = JSON.parse(JSON.parse(eventData))
					rq = questions
				} catch (e) {
					console.error('error parsing related questions', e)
				}
				if (Array.isArray(rq)) {
					updateConvo({ requestId, relatedQuestions: rq })
				}
			} catch (err) {
				sentryUtils.captureApiError(err.message, endpoint)
			}
		})
		eventSource.addEventListener('agent_status', (event) => {
			setAgentStatus({ requestId, status: event.data })
		})
		// eventSource.addEventListener('task_type', (event) => {
		// 	if (!widgetSession.isInstantSearchEnabled || ['search', 'sku-search'].includes(event.data) === false) return
		// })
		eventSource.onerror = function (err) {
			sentryUtils.captureApiError(err, endpoint)

			eventSource.close()
		}
	}

	return { fetchEntResults }
}
