import { useStore } from '../store/store.js';
import { useEntResults } from './useEntResults.js';
import { utils, sentryUtils } from '../lib/index.js';

export const useSubmitHandler = () => {
    const {
        widgetSession,
        convos,
        prependConvos,
        prevSrpRequestId,
        widgetLog,
        prompt,
        setPrompt,
        setSynthFilters,
        setEntLoading,
        requestSession,
        setIsPrompting,
        lastSearchPrompt,
    } = useStore((state) => ({
        widgetSession: state.widgetSession,
        convos: state.convos,
        prependConvos: state.prependConvos,
        prevSrpRequestId: state.prevSrpRequestId,
        widgetLog: state.widgetLog,
        prompt: state.prompt,
        setPrompt: state.setPrompt,
        setSynthFilters: state.setSynthFilters,
        setEntLoading: state.setEntLoading,
        requestSession: state.requestSession,
        setIsPrompting: state.setIsPrompting,
        lastSearchPrompt: state.lastSearchPrompt,
    }));

    const { fetchEntResults } = useEntResults();

    const handlePromptSubmit = async ({ websiteContext, filterData, originalFilters }) => {
        if (!prompt && !filterData) {
            setEntLoading(false);
            return;
        }
        setIsPrompting(false);
        setEntLoading(true);
        setPrompt('');        
        widgetLog('submit');
        
        // Reset synth filters when the user starts a new search
        if (!filterData) {    
            setSynthFilters([]);
        }

        try {              
            const userPrompt = filterData ? lastSearchPrompt : prompt.trim();
            const chronicle = utils.convertConvosToChronicle(convos, userPrompt);
 
            prependConvos({
                userPrompt: userPrompt,
            });

            requestSession();
            const data = JSON.stringify({
                user_id: widgetSession.id,
                product_url: widgetSession.url,
                product_name: widgetSession.productName,
                user_input: userPrompt,
                message_history: chronicle,
                client_id: '',
                client_name: 'conversant',
                prev_srp_request_id: prevSrpRequestId,
                website_context: {
                    website_context: websiteContext,
                },
                filters: filterData,
            });            
            await fetchEntResults(data, originalFilters);
        } catch (err) {
            setEntLoading(false);
            sentryUtils.captureError(err);
        }
    };

    return { handlePromptSubmit };
}; 